import { render, staticRenderFns } from "./Wb.vue?vue&type=template&id=01fbbe86&scoped=true"
import script from "./Wb.vue?vue&type=script&lang=js"
export * from "./Wb.vue?vue&type=script&lang=js"
import style0 from "./Wb.vue?vue&type=style&index=0&id=01fbbe86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fbbe86",
  null
  
)

export default component.exports